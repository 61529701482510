import React, { useCallback } from 'react';
import lightbulb from './lightbulb_banner.svg';
import styles from './HeaderBar.module.css';
import {
  Divider,
  Box,
  Button
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

function HeaderBar() {
  const navigate = useNavigate();
  const enquireOnClick = useCallback(() => navigate('/enquiries', { replace: true }), [navigate]);

  return (
    <header className={styles.header}>
      <Box className={styles.container}>
        <Box className={styles.item}>
          <Link to="/">
            <img src={lightbulb} alt='MyStartup' height={45} />
          </Link>
          <Divider orientation='vertical' />
        </Box>
        <Box className={styles.subcontainer}>
          <Box className={styles.item}>

            <Link to="/about" className={styles.link}>
              About
            </Link>
          </Box>
          <Box className={styles.item}>

            <Link to="/why" className={styles.link}>
              Why MyStartup?
            </Link>
          </Box>
          <Box className={styles.item}>

            <Link to="/learn" className={styles.link}>
              Learn
            </Link>
          </Box>
        </Box>
        <Box className={styles.item}>
          <Button
            variant="contained"
            onClick={enquireOnClick}>
            Enquire
          </Button>
        </Box>
      </Box>
      <Divider />
    </header>
  );
}

export default HeaderBar;