import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import styles from './Home.module.css';
import process from './mystartup_process.png'
import HeaderBar from '../../header/HeaderBar';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();
  const findOutOnClick = useCallback(() => navigate('/about', { replace: true }), [navigate]);
  return (
    <>
      <HeaderBar/>
      <main className={styles.main}>
        <div className={styles.test}>
          <h1 className={styles.h1}>Your Startup Idea Today</h1>
          <p className={styles.p}><span className={styles.highlight}>MyStartup</span> will enable you to turn <br/>your idea for 
          a startup into a <span className={styles.highlight}>reality.</span></p>
          <Button 
            variant='contained' 
            disableElevation
            size='large'
            onClick={findOutOnClick}>
              Find out More
          </Button>
        </div>
          <img 
          src={process} 
          alt='The Startup Process'
          className={styles.image}/>
        <div className={styles.test}>
          <h1 className={styles.h1}>What We Do</h1>
          <p className={styles.p}>MyStartup will work with you to <span className={styles.highlight}>design</span> the idea to your specifications, <br/>
          <span className={styles.highlight}>develop</span> it into a working product, and acquire the <span className={styles.highlight}>team</span> you need to <br/>continue to grow it.</p>
        </div>
      </main>
    </>
  );
}

export default Home;
