import React from 'react';
import styles from './Enquiries.module.css';
import HeaderBar from '../../header/HeaderBar';
import ContactForm from '../../contactform/ContactForm';


function Enquiries() {

  return (
    <>
      <HeaderBar />
      <main className={styles.main}>
        <ContactForm />
      </main>
    </>
  );
}

export default Enquiries
