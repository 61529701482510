import React, { useState, useReducer, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useForm } from "react-hook-form";
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './ContactForm.module.css';

function ContactForm() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [showCaptcha, setShowCaptcha] = useState(false)
  const [formData, setFormData] = useState({})
  const [submitMessage, setSubmitMessage] = useState('') 

  const serviceId = process.env.REACT_APP_EMAIL_JS_SERVICE
  const templateId = process.env.REACT_APP_EMAIL_JS_TEMPLATE
  const userId = process.env.REACT_APP_EMAIL_JS_USER

  const submitMessageElement = (
    <p>{submitMessage}</p>
  )

  const resetForm = () => {
    reset()
    setFormData({})
    setShowCaptcha(false)
  }

  const submitFormAndShowCaptcha = (data) => {
    setSubmitMessage('')
    setFormData(data)
    setShowCaptcha(true);
  };

  const sendEmail = (captchaValue) => {
    const params = {
      ...formData,
      'g-recaptcha-response': captchaValue,
    };

    emailjs.send(
      serviceId,
      templateId,
      params,
      userId,
    )
    .then(({ status }) => {
      if (status === 200) {
        setSubmitMessage('Message has been sent')
        console.log('Message has been sent')
      } else {
        setSubmitMessage('Message sending ran into an unexpected error, please try again later or contact us directly via email or phone.')
        console.log('Message has failed')
      }
      
      resetForm()
    }, (err) => {
      setSubmitMessage('Message sending ran into an unexpected error, please try again later or contact us directly via email or phone.')
      console.log(err)
      resetForm()
    })
    
  }

  return (
    <form className={styles.contact} onSubmit={handleSubmit(submitFormAndShowCaptcha)}>
      <h2>Contact Us</h2>

      <label htmlFor="fullname" className={errors.fullname ? styles.error: ''}>Full name *</label> 
      <br /><input className={styles.input} type="text"
        {...register("fullname", { required: true })}/><br />

      <label htmlFor="company">Company</label>
      <br /><input className={styles.input} type="text"
        {...register("company")}/><br />

      <label htmlFor="email" className={errors.email ? styles.error: ''}>Email *</label> 
      <br /><input className={styles.input} type="email"
        {...register("email", { required: true })}/><br />

      <label htmlFor="message" className={errors.message ? styles.error: ''}>Message *</label> 
      <br /><textarea className={styles.message} rows={5} type="text"
        {...register("message", { required: true })}/><br />

      {showCaptcha ?
        (
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={sendEmail} />
        ) : (<><input type="submit" value="Submit" /><br /></>)
      }

      {Object.keys(errors).length > 0 && <span className={styles.error}>There are empty fields that are required.</span>}

      {submitMessage !== '' && submitMessageElement }

    </form>
  );
}

export default ContactForm
