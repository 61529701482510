import styles from './NotFound.module.css';
import HeaderBar from '../../header/HeaderBar';

function NotFound() {
  return (
    <>
      <HeaderBar/>
      <main className={styles.main}>
          <h1 className={styles.h1}>404</h1>
          <p className={styles.p}> We could not find the page you are looking for</p>
      </main>
    </>
  );
}

export default NotFound;
